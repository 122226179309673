import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
  uri: 'https://a69dd2-37.myshopify.com/api/2024-04/graphql.json', // Adjust API version and store URL
  cache: new InMemoryCache(),
  headers: {
    'X-Shopify-Storefront-Access-Token': '37b7befd3b469393ec22d5c10838daa7',
  },
});

export default client;

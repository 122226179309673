import React, { useEffect, useRef, useState } from 'react';
import p5 from 'p5';


const DaysLivedVisualiser = () => {
    const sketchRef = useRef();
    const [birthday, setBirthday] = useState('');
    const [animationStarted, setAnimationStarted] = useState(false);
    const [daysLived, setDaysLived] = useState(0);

    useEffect(() => {
        const myP5 = new p5((p) => {
            let dots = [];
            let maxSpawnRate = 100;
            let textSizeGrowth = 0.05;
            let currentTextSize = 22;

            p.setup = () => {
                const canvasWidth = sketchRef.current.offsetWidth;
                const canvasHeight = sketchRef.current.offsetHeight;
                
                p.createCanvas(window.innerWidth * 0.6, window.innerHeight * 0.6).parent(sketchRef.current);
               // p.createCanvas(canvasWidth, canvasHeight).parent(sketchRef.current);
                p.background(0);
            };

            p.draw = () => {
                if (!animationStarted || dots.length >= daysLived) return;

                p.background(0);

                let progress = dots.length / daysLived;
                let spawnRate = maxSpawnRate * 1.1 * progress * (1 - progress);
                spawnRate = Math.max(1, spawnRate);

                for (let i = 0; i < spawnRate && dots.length < daysLived; i++) {
                    dots.push(new Dot(offScreenPosition(p), p));
                }

                dots.forEach(dot => {
                    dot.update();
                    dot.display(p);
                });

                currentTextSize += textSizeGrowth;
                p.textSize(currentTextSize);
                p.textAlign(p.CENTER, p.CENTER);
                p.fill(255, 255, 255, 150);  // Alpha value is 150
                p.text(dots.length.toLocaleString(), p.width / 2, p.height / 2);
            };

            function offScreenPosition(p) {
                let angle = p.random(p.TWO_PI);
                let r = p.sqrt(p.random(p.pow(p.max(p.width, p.height) / 2, 2), p.pow(p.max(p.width, p.height), 2)));
                let x = p.width / 2 + r * p.cos(angle);
                let y = p.height / 2 + r * p.sin(angle);
                return p.createVector(x, y);
            }

            class Dot {
                constructor(pos, p) {
                    this.pos = pos;
                    this.initialSize = p.random(3, 6);
                    this.size = this.initialSize;
                    this.center = p.createVector(p.width / 2, p.height / 2);
                }

                update() {
                    this.pos.lerp(this.center, 0.02);
                    let distToCenter = this.pos.dist(this.center);
                    this.size = this.initialSize * (distToCenter / (p.sqrt(p.pow(p.max(p.width, p.height), 2))));
                    this.size = Math.max(this.size, 1);
                }

                display(p) {
                    p.noStroke();
                    p.fill(255);
                    p.ellipse(this.pos.x, this.pos.y, this.size, this.size);
                }
            }
        });

        // Adjust canvas on window resize
        const resizeCanvas = () => {
            const canvasWidth = sketchRef.current.offsetWidth;
            const canvasHeight = sketchRef.current.offsetHeight;
            myP5.resizeCanvas(canvasWidth, canvasHeight);
        };
        window.addEventListener('resize', resizeCanvas);

        return () => {
            myP5.remove();
            window.removeEventListener('resize', resizeCanvas);
        };
    }, [animationStarted, daysLived]);

    const startAnimation = () => {
        if (birthday) {
            const birthDate = new Date(birthday);
            const today = new Date();
            const diffTime = Math.abs(today - birthDate);
            const lived = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setDaysLived(lived);
            setAnimationStarted(true);
        }
    };

    return (
        <div>
            
            
            <div ref={sketchRef} className="project-canvas"> 
            
            <div className="p5-button-container">
                <input
                    type="date"
                    className="date-input"
                    value={birthday}
                    onChange={e => setBirthday(e.target.value)}
                />

                  <button className="date-input" onClick={startAnimation}>Play</button></div>
            </div>
        </div>
    );
};

export default DaysLivedVisualiser;

import React, { useState, useRef, useEffect } from 'react';
import './CircleNavigation.css';

function CircleNavigation({ onProjectChange }) {
  const [rotation, setRotation] = useState(0);
  const [isVisible, setIsVisible] = useState(true); // State to toggle visibility
  const rotatingRef = useRef(false);
  const momentumRef = useRef(0);
  const lastAngleRef = useRef(0);
  const rotatingCircleRef = useRef(null);
  const [momentumZero, setMomentumZero] = useState(false)

  
  if (momentumRef === 0){
     // Once momentum is low, calculate the index and call the onProjectChange prop
     const normalizedRotation = ((rotation % 360) + 360) % 360;
     const index = Math.floor(normalizedRotation / 90) % 4; // Assume 4 projects for modulo operation
     onProjectChange(index);
  }

  const toggleVisibility = () => {
    setIsVisible(!isVisible); // Toggle the visibility state
  };

  const handleMouseMove = (event) => {
    if (!rotatingRef.current || !rotatingCircleRef.current) return;

    const rect = rotatingCircleRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const mouseX = event.clientX - centerX;
    const mouseY = event.clientY - centerY;
    const mouseAngle = Math.atan2(mouseY, mouseX) * 180 / Math.PI;
    const rotationChange = mouseAngle - lastAngleRef.current;
    setRotation(prevRotation => prevRotation + rotationChange);
    momentumRef.current = rotationChange; // Store the last rotation change as momentum
    lastAngleRef.current = mouseAngle;

    setMomentumZero(false);


    if(momentumRef < 0.1){
      setMomentumZero(true);
    }
  };

  const handleMouseUp = () => {
    rotatingRef.current = false;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    applyMomentum();


      setMomentumZero(true);
      console.log(momentumZero,"momentum 0")

  };



  useEffect(() => {
    // Attach the mouse up and mouse move handlers only when necessary
    return () => {
      // Cleanup function to ensure no leaks
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []); // Empty dependencies array ensures this only runs on mount and unmount

  const applyMomentum = () => {
    if (Math.abs(momentumRef.current) > 0.1) {
      setMomentumZero(false)
      setRotation(prevRotation => {
        const newRotation = prevRotation + momentumRef.current;
        momentumRef.current *= 0.991; // Decay the momentum over time
        return newRotation;
      });
      requestAnimationFrame(applyMomentum);
    } else {
      setMomentumZero(true)
      // Once the momentum is small enough, calculate the final selected segment
  
    }
  };
  
  useEffect(() => {
if(momentumZero){
   // Once momentum is low, calculate the index and call the onProjectChange prop
   const normalizedRotation = ((rotation % 360) + 360) % 360;
   const index = Math.floor(normalizedRotation / 90) % 4; // Assume 4 projects for modulo operation
   onProjectChange(index);
}
   
  
  }, [momentumZero]); // Empty dependencies array ensures this only runs on mount and unmount


  const handleMouseDown = (event) => {
    event.preventDefault();  // This stops the browser from initiating a drag-and-drop operation
    rotatingRef.current = true;
    const rect = rotatingCircleRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const mouseX = event.clientX - centerX;
    const mouseY = event.clientY - centerY;
    const startAngle = Math.atan2(mouseY, mouseX) * 180 / Math.PI;
    lastAngleRef.current = startAngle;
    momentumRef.current = 0;
  
    // Attach global events on mouse down
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };
  

  return (
    <div className="circleNavContainer">
    {/* Conditionally render the circle based on visibility state */}
  
    {isVisible && (
      <div className="circle" style={{ transform: `rotate(${rotation}deg)` }}>
        <img
          ref={rotatingCircleRef}
          src="./circleNav/circleNav.png"
          alt="Rotating Navigation Circle"
          className="rotatingCircle"
          draggable="false"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        />
      </div>
    )}

    {/* Toggle Button */}
   
    <img
      src='./circleNav/selectPage.png'
      alt="Select Page Background"
      className="backgroundImage"
    />
  </div>
  );
}

export default CircleNavigation;
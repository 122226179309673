import React, { useEffect, useRef } from 'react';
import p5 from 'p5';

const MatrixRain = () => {
    const sketchRef = useRef();
    let lastHoveredIndex = -1;
  
    useEffect(() => {
      let hoverSymbols = [];

      const getNextLetterIndex = () => {
        lastHoveredIndex = (lastHoveredIndex + 1) % 5;
        return lastHoveredIndex;
      };
  
      const rainbowColors = [
        [255, 0, 0],
        [255, 127, 0],
        [255, 255, 0],
        [0, 255, 0],
        [0, 0, 255]
      ];
  
      class Symbol {
        constructor(p, x, y, speed, first, index) {
          this.p = p;
          this.x = x;
          this.y = y;
          this.originalY = y;
          this.value = '';
          this.speed = speed;
          this.first = first;
          this.switchInterval = this.p.round(this.p.random(10, 20));
          this.index = index;
          this.opacity = 255;
          this.isFrozen = false;
          this.color = [5, 1, 5];
        }
        setToNextSymbol() {
          if (!this.isFrozen && this.p.frameCount % this.switchInterval === 0) {
            this.value = String.fromCharCode(
              0x30A0 + this.p.round(this.p.random(0, 96))
            );
          }
        }
        rain() {
          if (!this.isFrozen) {
            this.y = (this.y >= this.p.height) ? this.originalY : this.y += this.speed;
          }
        }
      }

      class Stream {
        constructor(p, startY = 0) {
          this.p = p;
          this.symbols = [];
          this.totalSymbols = this.p.round(this.p.random(5, 35));
          this.speed = this.p.random(1, 2);
          this.startY = startY;
        }
        generateSymbols(x, y) {
          let first = this.p.round(this.p.random(0, 4)) === 1;
          y += this.startY;
          for (let i = 0; i <= this.totalSymbols; i++) {
            let symbol = new Symbol(this.p, x, y, this.speed, first, i % 5);
            symbol.setToNextSymbol();
            this.symbols.push(symbol);
            y -= 18;
            first = false;
          }
        }
      }

      const sketch = (p) => {
        let streams = [];

        p.setup = () => {
          p.createCanvas(p.windowWidth, p.windowHeight/1.4);
          p.background(0);

          let x = 0;
          for (let i = 0; i <= p.width / 18; i++) {
            let startY = p.random(-500, 0);
            let stream = new Stream(p, startY);
            stream.generateSymbols(x, p.random(-1000, 0));
            streams.push(stream);
            x += 18;
          }

          p.textSize(18);
          p.textFont('monospace');
        };

        p.draw = () => {
          p.background(0, 150);
          streams.forEach((stream) => {
            stream.symbols.forEach((symbol) => {
              if (!symbol.isFrozen && 
                  p.mouseX >= symbol.x && p.mouseX < symbol.x + 18 &&
                  p.mouseY >= symbol.y && p.mouseY < symbol.y + 18) {
                symbol.isFrozen = true;
                symbol.index = getNextLetterIndex();
                symbol.value = "habby"[symbol.index];
                symbol.color = rainbowColors[symbol.index];
                hoverSymbols.push(symbol);
              }

              if (symbol.isFrozen) {
                p.fill(...symbol.color, symbol.opacity);
                symbol.opacity -= 2;
              } else {
                p.fill(0, 255, 70, symbol.opacity);
              }
              p.text(symbol.value, symbol.x, symbol.y);
              if (!symbol.isFrozen) {
                symbol.rain();
                symbol.setToNextSymbol();
              }
            });
          });

          hoverSymbols = hoverSymbols.filter(symbol => symbol.opacity > 0);
        };

        p.windowResized = () => {
          p.resizeCanvas(p.windowWidth, p.windowHeight);
        };
      };

      const myP5 = new p5(sketch, sketchRef.current);

      return () => {
        myP5.remove();
      };
    }, []);

    return <div ref={sketchRef} className="matrix-rain-banner" />;
};

export default MatrixRain;

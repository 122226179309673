import React, { useEffect, useRef } from 'react';
import p5 from 'p5';

const PareidoliaGenerator = () => {
  const sketchRef = useRef();
  const p5Instance = useRef(null);

  useEffect(() => {
    const sketch = (p) => {
      let gradients = [];
      let shapes = [];
      let img;
      let isPaused = false; // Local control flag
      let staticShapeNo = 0;

      p.preload = () => {
        gradients = [
          p.loadImage('./pareidolia/smudge1.png'),
          p.loadImage('./pareidolia/smudge8.png'),
          p.loadImage('./pareidolia/smudge9.png'),
          p.loadImage('./pareidolia/smudge10.png'),
          p.loadImage('./pareidolia/smudge7.png')
        ];
      };

      p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight);
        p.noStroke();
        p.background(0);

        // Initialize shapes
        for (let i = 0; i < 5; i++) {
          shapes.push(new StaticShape(p, gradients, p.random(p.width), p.random(p.height), p.random(50)));
        }
        for (let i = 0; i < 15; i++) {
          shapes.push(new Shape(p, gradients, p.random(p.width), p.random(p.height), p.random(50)));
        }
        shapes.forEach(shape => shape.display());
        img = p.get(); // Capture initial state
      };

      p.draw = () => {
        if (!isPaused) {
          p.background(0);
          p.tint(255, 225); // Slight opacity on the tint to blend shapes
          p.image(img, 0, 0); // Redraw the last captured image
          p.noTint();

          shapes.forEach((shape, index) => {
            shape.update();
            shape.display();
            if (p.millis() - shape.creationTime > 30000) {
              // Replace expired shape with a new one
              shapes[index] = new Shape(p, gradients, p.random(p.width), p.random(p.height), p.random(50));
          

            }
          });

          img = p.get(); // Update snapshot only when not paused
        }
      };


       // Define your shape classes here as before, no need to change those for this logic

       class Shape {
        constructor(p, gradients, x, y, noiseSeed) {
          this.p = p;
          this.x = x;
          this.y = y;
          this.size = p.random(500, 5600);
          this.noiseSeed = noiseSeed;
          this.creationTime = p.millis();
          this.gradient = p.random(gradients);
          this.fadeRate = p.random(0.1, 1.5);
          this.initialTint = p.random([0, 0]);
        }

        update() {
          this.x += this.p.map(this.p.noise(this.noiseSeed, this.p.frameCount * 0.01), 0, 1, -5, 5);
          this.y += this.p.map(this.p.noise(this.noiseSeed + 10, this.p.frameCount * 0.01), 0, 1, -5, 5);
        }

        display() {
          if (this.p.millis() - this.creationTime < 30000) {
            this.p.push();
            this.p.translate(this.x, this.y);
            this.p.imageMode(this.p.CENTER);
            let greyscale = this.initialTint + (this.p.millis() - this.creationTime) / 30000 * 255 * this.fadeRate;
            greyscale = this.p.constrain(greyscale, 0, 255);
            this.p.tint(greyscale);
            let oscillatingSize = this.size + this.p.sin(this.p.frameCount * 0.05) * 2;
            let rotationAngle = this.p.map(this.p.millis() - this.creationTime, 0, 30000, 0, this.p.TWO_PI);
            this.p.scale(0.5);
            this.p.scale(oscillatingSize / this.size);
            this.p.rotate(rotationAngle);
            this.p.image(this.gradient, 0, 0, this.size, this.size);
            this.p.pop();
          }
        }
      }

      class StaticShape {
        constructor(p, gradients, x, y, noiseSeed) {
          this.p = p;
          this.x = x;
          this.y = y;
          this.size = p.random(1000, 3600);
          this.noiseSeed = noiseSeed;
          this.creationTime = p.millis();
          this.gradient = p.random(gradients);
          this.fadeRate = p.random(0, 0);
          this.initialTint = p.random([230, 255]);
        }

        update() {
          this.size += this.p.map(this.p.noise(this.noiseSeed, this.p.frameCount * 0.02), 0, 1, -2, 2);
        }

        display() {
          if (this.p.millis() - this.creationTime < 30000) {
            this.p.push();
            this.p.translate(this.x, this.y);
            this.p.imageMode(this.p.CENTER);
            let greyscale = this.initialTint + (this.p.millis() - this.creationTime) / 300000 * 255 * this.fadeRate;
            greyscale = this.p.constrain(greyscale, 0, 255);
            this.p.tint(greyscale);
            this.p.image(this.gradient, 0, 0, this.size, this.size);
            this.p.pop();
          }
        }
      }


      p.mousePressed = () => {
        if (p.mouseX >= 0 && p.mouseX <= p.width && p.mouseY >= 0 && p.mouseY <= p.height) {
          isPaused = !isPaused; // Toggle pause on mouse click within the canvas
        }
      };
    };

    p5Instance.current = new p5(sketch, sketchRef.current);

    return () => {
      if (p5Instance.current) {
        p5Instance.current.remove();
        p5Instance.current = null;
      }
    };
  }, []); // Dependency array is empty to avoid re-creating the instance unnecessarily

  return <div ref={sketchRef}></div>;
};

export default PareidoliaGenerator;

import React, { useState } from 'react';
import P5Header from '../../components/header/P5HeaderBar/P5Header';
import Piano from '../../components/p5/Piano';
import Footer from '../../components/footer/footer';
import './P5Page.css';

import DaysLivedVisualiser from  '../../components/p5/DaysLivedVisualiser';
import ImageMosaic from  '../../components/p5/ImageMosaic';
import NameGenerator from  '../../components/p5/NameGenerator';

import PosterHeader from '../../components/header/PosterHeaderBar/PosterHeader';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import CircleNavigation from '../../components/circleNavigation/CircleNavigation';
import P5ProjectSelector from '../../components/p5/p5ProjectSelector/P5ProjectSelector'

function P5Page() {

    const [selectedProject, setSelectedProject] = useState('DaysLivedVisualiser');

    const projects = ['DaysLivedVisualiser', 'ImageMosaic', 'NameGenerator'];
  
    const handleProjectChange = (index) => {
      setSelectedProject(projects[index]);
    };
  
    const projectComponents = {
      DaysLivedVisualiser: <DaysLivedVisualiser />,
      ImageMosaic: <ImageMosaic />,
      NameGenerator: <NameGenerator />
    };

    return (
        <div className=".page-background">
            <PosterHeader/>
            <div className="relative w-full">
              <Piano />
              <div className="piano-overlay">
                  <h1 className="text-xxl text-white text-center">Explore P5.js Projects</h1>
              </div>
            </div>

            <div className="content-background">


      
      <div>{projectComponents[selectedProject]}</div>

            
            </div>
            <Footer/>
            <CircleNavigation onProjectChange={handleProjectChange} />
        </div>
    );
}

export default P5Page;

import React, { useEffect, useState } from 'react';

import PosterHeader from '../components/header/PosterHeaderBar/PosterHeader';
import MatrixRain from '../components/p5/MatrixRain';
import client from '../components/shopify/ApolloClient'; // Adjust the import path as necessary
import { useQuery, gql, useMutation } from '@apollo/client';
import Footer from '../components/footer/footer';

import './PosterPage.css';

// Define static product IDs here
const productIds = [
  "gid://shopify/Product/8988896690505",
  "gid://shopify/Product/8988945613129",
  "gid://shopify/Product/8988961472841",
  "gid://shopify/Product/8993489486153",
  "gid://shopify/Product/8993462813001",
  "gid://shopify/Product/8993522942281",

  // Add more product IDs as needed
];


const FETCH_PRODUCTS_QUERY = gql`
query FetchProducts($ids: [ID!]!) {
  nodes(ids: $ids) {
    ... on Product {
      id
      title
      descriptionHtml
      images(first: 1) {
        edges {
          node {
            src
          }
        }
      }
      variants(first: 1) {
        edges {
          node {
            id
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 { # Assuming this field exists and holds the original price
              amount
            }
          }
        }
      }
    }
  }
}
`;



const CREATE_CHECKOUT_MUTATION = gql`
  mutation createCheckout($variantId: ID!, $quantity: Int!) {
    checkoutCreate(input: {
      lineItems: [{ variantId: $variantId, quantity: $quantity }]
    }) {
      checkout {
        id
        webUrl
      }
    }
  }
`;

function PosterPage() {

  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      const diff = midnight - now; // milliseconds until midnight

      // Convert diff to hours, minutes, and seconds
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diff / (1000 * 60)) % 60);
      const seconds = Math.floor((diff / 1000) % 60);

      setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  
  // Define the mutation inside the component
  const [createCheckout, { data: checkoutData, loading: checkoutLoading, error: checkoutError }] = useMutation(CREATE_CHECKOUT_MUTATION, { client });
  
  
  const handleAddToCart = async (variantId) => {
    const result = await createCheckout({
      variables: {
        variantId,
        quantity: 1,
      },
    });
    
    if (result.data.checkoutCreate.checkout) {
      window.location.href = result.data.checkoutCreate.checkout.webUrl; // Redirect to Shopify checkout page
    }
  };
   


  const { loading, error, data } = useQuery(FETCH_PRODUCTS_QUERY, {
    variables: { ids: productIds },
    client: client,
  });

  if (loading) return <p className="text-white">Loading...</p>;
  if (error) {
    console.error(error);
    return <p className="text-white">Error :( Check console for details.</p>;
  }

// PosterPage component

return (
  <div className="relative w-full min-h-screen">
    <MatrixRain />
    <div className="relative z-10">
      <PosterHeader />
      {/* Hero Section */}
     {/* Hero Section with Countdown */}
     <div className="text-center p-12 text-white">
          <div className="countdown-text mb-4">
            The next posters in: <span className="countdown">{timeLeft}</span>
          </div>
          <h1 className="text-5xl text-white font-bold mb-4">A2 Poster Collection</h1>
          <p className="text-xl">300gsm Semi-Gloss Paper</p>
        </div>
      {/* Posters Section - Wrapped in an additional div for width control */}
      <div className="max-w-screen-lg mx-auto px-4 md:px-4">
      <div className="flex flex-wrap justify-center -mx-4">

      {data.nodes.map((product) => (
  <div key={product.id} className="px-4 mb-8 w-full sm:w-1/2 md:w-1/3">
    <div className="relative rounded-lg overflow-hidden shadow-lg poster-card">
      <div className="absolute top-0 right-0 bg-red-500 text-white py-1 px-3 text-sm font-bold">Sale</div>
      <img src={product.images.edges[0].node.src} alt={product.title} className="w-full h-full object-cover" />
      <div className="p-4">
        <h2 className="font-semibold text-xl mb-2">{product.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} className="mb-4" />
        <p className="font-semibold">
          <span className="line-through">{product.variants.edges[0].node.compareAtPriceV2.amount}</span> {product.variants.edges[0].node.priceV2.amount} {product.variants.edges[0].node.priceV2.currencyCode}
        </p>
        <button
          onClick={() => handleAddToCart(product.variants.edges[0].node.id)}
          className="mt-4 bg-blue-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add to Cart
        </button>
      </div>
    </div>
  </div>
))}

</div>

      </div>
    </div>
    <Footer />
  </div>
);

}

export default PosterPage;


import React, { useEffect,useState, useRef } from 'react';
import p5 from 'p5';

const NameGenerator = () => {
    const sketchRef = useRef(null);
    const [lockedIn, setLockedIn] = useState(false);
    const [selectedName, setSelectedName] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [nameScores, setNameScores] = useState(new Map());
    const [lifespan, setLifespan] = useState(null);
    const [emotion, setEmotion] = useState("");
    const [death, setDeath] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);



  useEffect(() => {
    if (!lockedIn && sketchRef.current) {
    let myP5;
    

    // Define the sketch
    const sketch = (p) => {
      let letterVArray = [];
      let names = [];
      let vectors = new Map();
      let scores = new Map();

      


        // Helper function to set up and resize the canvas
        const setupCanvas = () => {
            p.resizeCanvas(window.innerWidth * 0.9, window.innerHeight * 0.9); // Adjust canvas size
            p.background(0);
          };

      p.setup = () => {
        p.createCanvas(window.innerWidth * 0.6, window.innerHeight * 0.6).parent(sketchRef.current);
        p.textFont('ZCOOL KuaiLe-Regular', 60);
        p.textAlign(p.CENTER, p.CENTER);
        p.background(0);
        initializeLetters(p);
        fetchNames();
      };

      p.draw = () => {
        p.background(0);
        letterVArray.forEach((lv) => {
          lv.detectEdge();
          lv.move();
          let dist = lv.dist(p.mouseX, p.mouseY);
          let score = 1 / Math.max(dist, 1);
          scores.set(lv.letter, score);
        });

        names.forEach(name => {
          let scoreTotal = calculateNameScore(name);
          nameScores.set(name, scoreTotal);
        });

        if (nameScores.size > 0) {
          let highestScoringName = [...nameScores.entries()].reduce((a, b) => a[1] > b[1] ? a : b)[0];
          p.text(highestScoringName, p.width / 2, p.height / 10); // Adjust text position based on height
        } else {
          p.text("Loading names...", p.width / 2, p.height / 10);
        }
      };

      p.windowResized = () => {
        setupCanvas(); // Resize when window size changes
      };
      

      function initializeLetters(p) {
        let margin = 100;
        p.translate(margin * 4, margin * 4);
        let gap = 20;
        let counter = 65; // ASCII for 'A'
        for (let y = 0; y < p.height - gap; y += gap) {
          for (let x = 0; x < p.width - gap; x += gap) {
            if (counter < 91) {
              let letter = String.fromCharCode(counter);
              let color = 'AEIOU'.includes(letter) ? p.color(255, 204, 0) : p.color(255);
              p.fill(color);
              p.text(letter, x, y);
              let lv = new LetterVector(letter, x, y, p.random(1), p.random(1), p);
              letterVArray.push(lv);
              vectors.set(letter, p.createVector(x + (gap / 2), y + (gap / 2)));
              counter++;
            }
          }
        }
      }

      async function fetchNames() {
        console.log("Fetching names...");
        try {
          // Fetch data just once or as many times as necessary
          const response = await fetch('https://randomuser.me/api/?results=20'); // Fetch 20 users at once
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          data.results.forEach(item => {
            let fn = item.name.first;
            names.push(fn);
          });
          console.log("Names fetched successfully:", names);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      
      
      

      function calculateNameScore(name) {
        let scoreTotal = 0;
        for (let i = 0; i < name.length; i++) {
          let letter = name.charAt(i).toUpperCase();
          if (scores.has(letter)) {
            let letterScore = scores.get(letter);
            if (i === 0) {
              letterScore *= 2;
            } else if (i > 4) {
              letterScore /= (i - 3);
            }
            scoreTotal += letterScore;
          }
        }
        return scoreTotal;
      }

      class LetterVector {
        constructor(letter, xpos, ypos, xspeed, yspeed, p) {
          this.letter = letter;
          this.xpos = xpos;
          this.ypos = ypos;
          this.xspeed = xspeed;
          this.yspeed = yspeed;
          this.p = p;
        }

        move() {
          this.xpos += this.xspeed;
          this.ypos += this.yspeed;
          this.p.text(this.letter, this.xpos, this.ypos);
        }

        detectEdge() {
          if (this.xpos > this.p.width || this.xpos < 0) {
            this.xspeed *= -1;
          }
          if (this.ypos > this.p.height || this.ypos < 0) {
            this.yspeed *= -1;
          }
        }

        dist(x, y) {
          return Math.abs(this.xpos - x) + Math.abs(this.ypos - y);
        }
      }
    };

    myP5 = new p5(sketch, sketchRef.current);
    return () => myP5.remove();
  }}, [lockedIn, sketchRef]);


  const randomEmotionDescription = () => {
    const descriptions = [
      "Radiantly Happy: With a smile that lights up any room, this individual exudes a contagious joy. They approach each day with optimism and a zest for life that inspires everyone around them.",
      "Quietly Content: Your child enjoys a deep, satisfying contentment. They appreciate the simple pleasures of life and find comfort in routine and familiarity.",
      "Adventurously Excited: Always ready for the next big adventure, this spirited individual thrives on new experiences and challenges with an infectious enthusiasm.",
      "Steadily Determined: With unwavering focus and determination, your child sets goals and pursues them with relentless persistence, never deterred by obstacles.",
      "Deeply Thoughtful: Reflective and introspective, this individual delves deep into thoughts, often pondering life's complexities and the subtleties of human nature.",
      "Explosively Joyful: Their laughter is booming and their energy boundless. Your child finds immense joy in every aspect of life, spreading happiness like wildfire.",
      "Pensively Melancholic: Often found lost in thought, they possess a soulful melancholy that reflects a profound understanding and empathy towards the pains of existence.",
      "Mildly Amused: With a light chuckle and a wry smile, your child takes life's oddities with humor, always finding a way to see the lighter side of situations.",
      "Fiercely Motivated: A powerhouse of drive and ambition, this individual tackles every task with a fiery intensity and a clear vision of success.",
      "Overwhelmingly Anxious: Burdened by worries and what-ifs, your child often feels overwhelmed by the future, struggling to find peace amidst the anxiety.",
      "Curiously Inquisitive: Driven by an insatiable curiosity, they are always questioning and learning, eager to understand more about the world and themselves.",
      "Passionately Angry: Fueled by a deep sense of injustice or frustration, their anger burns bright, pushing them to make changes and stand up for what they believe in.",
      "Subdued and Tired: Weariness clings to them like a heavy cloak, sapping the energy and making even simple tasks feel like monumental efforts.",
      "Blissfully Serene: In a state of peaceful calm, they navigate life with a serene tranquility, untouched by the chaos that may swirl around them.",
      "Hopelessly Romantic: Eternal optimists when it comes to love, they dream of deep connections and fairy-tale endings, their hearts always open.",
      "Skeptically Reserved: Always questioning the motives of others and the likelihood of outcomes, they approach life with a cautious skepticism.",
      "Uncharacteristically Indifferent: Nothing seems to matter much anymore, and they drift through days with a detachment that's uncharacteristic of their usual self.",
      "Visibly Excited: Easily uplifted by the smallest victories, their excitement is palpable, bringing a dynamic energy to even the most mundane activities.",
      "Bitterly Disappointed: Haunted by what could have been, they navigate through a fog of disappointment, struggling to let go of dashed hopes.",
      "Energetically Dynamic: A whirlwind of activity, they move through life with a dynamic vigor, always busy and endlessly productive.",
      "Just Dogs: They just loved dogs and wouldnt stop going on about them, something about a little Bedlington Terrier."
    ];
    const randomIndex = Math.floor(Math.random() * descriptions.length);
    setEmotion(descriptions[randomIndex]);
  };

  const randomDeathDescription = () => {
    const descriptions = [
      "peacefully in their sleep, cherished dreams their last companions",
      "heroically saving others from a burning building, forever remembered as a hero. But they could of been a bit more gentle with one of the kids.",
      "quietly at home, surrounded by loving family, sharing stories and laughter till the end.",
      "during an adventurous journey around the world, living fully until their last breath.",
      "suddenly due to an unexpected accident I believe involving fork lifts, leaving shock and memories behind.",
      "after a long and courageous battle with illness, showing strength and grace throughout. Except the very very end that was something else...",
      "during a moment of pure joy, their heart simply full to bursting.",
      "in solitude among the serene beauty of nature, just as the sun rose over the horizon.",
      "laughing heartily at a joke, a final moment filled with genuine happiness.",
      "in the midst of their life’s work, dedicated to the end to making a difference.",
      "under the stars, camping in their favorite place, at peace with the universe.",
      "in despair, alone, their light dimming unnoticed in the chaos of the world.",
      "fighting passionately for a cause they believed in, their spirit indomitable to the last.",
      "lost at sea during a storm. The day before was beautiful and was exactly what they set out for, their love for the ocean everlasting.",
      "during a risky surgery, odds stacked against them, bravely facing the outcome with hope.",
      "from lung cancer init, kids dont smoke!",
      "from sheer old age, their story woven richly through the tapestry of time.",
      "from a broken heart, soon after the passing of their lifelong love.",
      "in a natural disaster, showing bravery and concern for others until the end.",
      "after a fulfilling day spent with family and friends, their heart content and full.",
      "in mystery, their passing leaving questions that echo in the memories of those who knew them... Never to be answered.",
      "mysteriously wet.",
      "squeezing out something stinky.",
      "shaken, not stirred.",
      "eaten by a pack of ravenous Pomeranians. I think they were all a womens named Sara?",
      "lost in a pool of love.",
      "from a suspected drug overdose.",
      "from Spider-Man. Wait what, I thought he was meant to be a hero...",
    ];
    const randomIndex = Math.floor(Math.random() * descriptions.length);
    setDeath(descriptions[randomIndex]);
  };
  
  
   const handleLockIn = () => {
        if (nameScores.size > 0) {
            const highestScoringName = [...nameScores.entries()].reduce((a, b) => a[1] > b[1] ? a : b)[0];
            setSelectedName(highestScoringName);
            setLockedIn(true);
            setLoading(true);
            setLoadingProgress(0);
            simulateLoading();
        } else {
            alert("Still calculating the best name, please wait a moment.");
        }
    };

    const simulateLoading = () => {
        const lifespanGenerated = Math.floor(Math.pow(Math.random(), 0.5) * 90) + 20;
        generateLifespan();

        let year = 0;
        const interval = setInterval(() => {
            setLoadingProgress((year / lifespanGenerated) * 100);
            year++;
            if (year > lifespanGenerated) {
                clearInterval(interval);
                setLoading(false);
                randomEmotionDescription();
                randomDeathDescription();
                fetchUserDetails();
            }
        }, 50);
    };


  

  const fetchUserDetails = (name) => {
    fetch(`https://randomuser.me/api/?results=1&inc=name,location,email,login,dob,registered,phone,cell,id,picture,nat&nat=${name}`)
      .then(res => res.json())
      .then(data => setUserInfo(data.results[0]))
      .catch(err => console.error('Error fetching user data:', err));
  };

  const generateLifespan = () => {
    // Weighted random number generation for lifespan
    const age = Math.floor(Math.pow(Math.random(), 0.5) * 90) + 20;
    setLifespan(age);
  };
 
  return (
    <div>
        {!lockedIn ? (
            <div>
                <div ref={sketchRef} className="mosaic-project-canvas"></div>
                <button onClick={handleLockIn}>Lock in Baby</button>
            </div>
        ) : loading ? (
            <div>
                <h2>Loading Life Simulation...</h2>
                <div style={{ width: '100%', backgroundColor: '#ddd' }}>
                    <div style={{ height: '20px', width: `${loadingProgress}%`, backgroundColor: 'green' }}></div>
                </div>
                <p>Simulating year: {Math.floor((loadingProgress / 100) * lifespan)}</p>
            </div>
        ) : (
            <div>
                {userInfo ? (
                    <>
                        <h1>{selectedName} {userInfo.name?.last}</h1>
                        <img src={userInfo.picture?.large} alt={`${userInfo.name?.first} ${userInfo.name?.last}`} />
                        <p>Emotional Temperament: {emotion}</p>
                        <p>Username: {userInfo.login?.username}</p>
                        <p>Password: {userInfo.login?.password}</p>
                        <p>Lifespan: They lived up to the age of {lifespan} years, before passing away {death}</p>
                        <p>Residence: {userInfo.location?.street.name}, {userInfo.location?.city}, {userInfo.location?.state}, {userInfo.location?.country}</p>
                    </>
                ) : (
                    <p>Loading user info...</p>
                )}
            </div>
        )}
    </div>
);

};

export default NameGenerator;
import React, {useState} from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/HeaderBar/Header';
import PosterHeader from '../../components/header/PosterHeaderBar/PosterHeader';
import './HomePage.css'; // Ensure this CSS file is linked
import { useNavigate } from 'react-router-dom';
import { useTransition } from '../../contexts/TransitionContext'; // Adjust the path as necessary
import FishTank from '../../components/p5/FishTank';

function HomePage() {
  const navigate = useNavigate();

  const { goToHome, goToPosters, goToArt, toggleSunset } = useTransition();
  const [style, setStyle] = useState({
        opacity: 1,
        transform: 'translateY(0px)'
    });



  const handleSunSet = () => {
    // Set new style to give feedback
    setStyle({
        opacity: 0.5, // Decrease opacity for feedback
        transform: 'translateY(5px)' // Move image up for feedback
    });

    // Reset style after a short delay
    setTimeout(() => {
        setStyle({
            opacity: 1, // Reset opacity
            transform: 'translateY(0px)' // Reset position
        });
    }, 200); // Delay in milliseconds, adjust as needed for visible feedback
    toggleSunset();
};

  const handleHomeClick = () => {
    goToHome();
    navigate("/");
  };

  const handleParedoliaClick = () => {
    goToArt();
    navigate("/paredolia");
  };

  const handlePostersClick = () => {
    goToPosters();
    navigate("/posters");
  };

  const handlePondClick = () => {

    navigate("/pond");
  };


  return (
    <div>
    <div className="page-background">
      <PosterHeader />


      <img  className='sunButton'  style={{ marginRight: 20,  opacity: style.opacity, transform: style.transform }} onClick={handleSunSet} src="/SunKey2.png" alt="Sun Key"></img>
       
      <div className="hero-section">
        <div className="hero-text-section">
          {/* Responsive font size using viewport width (vw) */}
          <h1 style={{ fontFamily: 'coolvetica' }}>Welcome</h1>
          <p className="hero-text">
   300gsm A2 Posters available in the store or if your feeling creative, use the Pareidolia Generator to find shapes and patterns to start your art ideas.
</p>

        </div>

      </div>

      <div className="navigation-buttons">
        <button className="color-button color-button-red" onClick={handlePostersClick}>Posters</button>
        <button className="color-button color-button-yellow" onClick={handleParedoliaClick}>Pareidolia</button>
        <button className="color-button color-button-blue" onClick={handlePondClick}>Pond</button>
      </div>


      <Footer />
    </div>
     
      </div>
  );
}

export default HomePage;

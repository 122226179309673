import React, { useState, useEffect } from 'react';
import './P5Header.css';
import { useNavigate } from 'react-router-dom';
import { useTransition } from '../../../contexts/TransitionContext';

function P5Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for toggle menu
  const navigate = useNavigate();
  const { goToHome, goToArt, goToPosters } = useTransition();

  const handleHomeClick = () => {
    goToHome();
    navigate("/");
  };

  const handlePosterClick = () => {
    goToPosters();
    navigate("/posters");
  };

  const handleParedoliaClick = () => {
    goToArt();
    navigate("/paredolia"); // Ensure this is the correct path for the art page
  };


  const handlePondClick = () => {
    goToArt();
    navigate("/pond"); // Ensure this is the correct path for the art page
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu visibility
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const menuActions = {
    'Home': handleHomeClick,
    'Posters': handlePosterClick,
    'Paredolia': handleParedoliaClick,
    'Pond': handlePondClick,
  };

  const menuItems = ['Home', 'Posters', 'Paredolia', 'Pond'];

  return (
    <header className={`p5-header ${isScrolled ? 'visible' : ''}`}>
      <div className="burger-menu" onClick={toggleMenu}>
        ☰
      </div>
      <div className={`title ${isScrolled ? 'scrolled' : ''}`}>

        <span style={{ color: 'white' }} className="p5-letter">h</span>
        <span style={{ color: 'white' }} className="p5-letter">a</span>
        <span style={{ color: 'white' }} className="p5-letter">b</span>
        <span style={{ color: 'white' }} className="p5-letter">b</span>
        <span style={{ color: 'white' }} className="p5-letter">y</span>

      </div>
      <div className="divider"></div>

      <nav className={`p5-menu ${isMenuOpen ? 'open' : ''}`}>
        {menuItems.map((item) => (
          <React.Fragment key={item}>
            <button className="p5-menu-item" onClick={menuActions[item]}>{item}</button>
            {item !== 'Contact' && <div className="divider"></div>}
          </React.Fragment>
        ))}
      </nav>
    </header>
  );
}

export default P5Header;

import React, { useEffect, useRef, useState } from 'react';
import p5 from 'p5';

const ImageMosaic = () => {
    const sketchRef = useRef();
    const [isReadyToProcess, setIsReadyToProcess] = useState(false);  // State to control the readiness

    useEffect(() => {
        const myP5 = new p5((p) => {
            let webcam;
            const scl = 20;  // Scale factor for each image cell
            let images = [];
            let avgColors = [];

            const loadImages = async () => {
                const r = require.context('../../../public/gems', false, /\.png$/);
                const promises = r.keys().map(item => 
                    new Promise(resolve => {
                        const img = p.loadImage(r(item), () => {
                            resolve(img);
                        });
                    })
                );
                return await Promise.all(promises);
            };

            p.setup = async () => {
                p.createCanvas(window.innerWidth * 0.6, window.innerHeight * 0.6).parent(sketchRef.current);
                webcam = p.createCapture(p.VIDEO);
                webcam.size(p.width / scl, p.height / scl);
                webcam.hide();
                console.log("Webcam initialized.");

                images = await loadImages();
                images.forEach(img => {
                    img.loadPixels();
                    avgColors.push(calculateAverageColor(img));
                });

                console.log("All images processed and loaded.");
            };

            p.draw = () => {
                if (isReadyToProcess && webcam) {
                    webcam.loadPixels();
                    if (webcam.pixels.length > 0) {
                        p.background(0);
                        drawMosaic();
                    }
                }
            };

            function drawMosaic() {
                for (let x = 0; x < webcam.width; x++) {
                    for (let y = 0; y < webcam.height; y++) {
                        const index = (x + y * webcam.width) * 4;
                        const r = webcam.pixels[index];
                        const g = webcam.pixels[index + 1];
                        const b = webcam.pixels[index + 2];
                        const closestImage = findClosestImage(r, g, b);
                        if (images[closestImage]) {
                            p.image(images[closestImage], x * scl, y * scl, scl, scl);
                        }
                    }
                }
            }

            function calculateAverageColor(img) {
                let total = [0, 0, 0];
                for (let i = 0; i < img.pixels.length; i += 4) {
                    total[0] += img.pixels[i];
                    total[1] += img.pixels[i + 1];
                    total[2] += img.pixels[i + 2];
                }
                const numPixels = img.pixels.length / 4;
                return total.map(t => t / numPixels);
            }

            function findClosestImage(r, g, b) {
                let record = Infinity;
                let closestIndex = -1;
                avgColors.forEach((color, index) => {
                    const distance = p.dist(color[0], color[1], color[2], r, g, b);
                    if (distance < record) {
                        record = distance;
                        closestIndex = index;
                    }
                });
                return closestIndex;
            }
        });

        return () => {
            myP5.remove();
        };
    }, [isReadyToProcess]);

    return (
        <div>
          <div ref={sketchRef} className="mosaic-project-canvas"></div>
            <button className="start-button" onClick={() => setIsReadyToProcess(true)}>Start Processing</button>
        </div>
    );
};

export default ImageMosaic;

import React from 'react';
import './footer.css'; // Ensure your CSS file is correctly linked

function Footer() {
    return (
        <footer style={{minHeight: '300px'}} className="footer bg-neutral-950 py-1 z-10 w-full min-h-16">
        <div className="container mx-auto px-4">
            <div className="flex justify-around items-center">
                
                {/* Habby Text Section */}
                <div className="text-highlight">


            <span style={{ color: '#f9f9f9' }} className="letterFooter">h</span>
            <span style={{ color: '#f9f9f9' }} className="letterFooter">a</span>
            <span style={{ color: '#f9f9f9' }} className="letterFooter">b</span>
            <span style={{ color: '#f9f9f9' }} className="letterFooter">b</span>
            <span style={{ color: '#f9f9f9' }} className="letterFooter">y</span>
                </div>

                {/* Right Section for Social Links and Contact */}
                {/* <div className="text-sections"> */}
                    {/* <div className="text-highlight mb-4">
                       

                        <img src="/path-to-your-image/facebook-icon.png" alt="Facebook" className="inline-block w-8 h-8 mx-2" />
                        <img src="/path-to-your-image/twitter-icon.png" alt="Twitter" className="inline-block w-8 h-8 mx-2" />
                        <img src="/path-to-your-image/instagram-icon.png" alt="Instagram" className="inline-block w-8 h-8 mx-2" />
                    </div> */}

                        {/* <h2 className="text-xl">Enjoy </h2>
                        <p>@yourdomain.com</p>
                        <h2 className="text-xl">Thanks</h2>

                </div> */}

            </div>
            <div className="mt-8 text-center">
                <p>&copy; {new Date().getFullYear()} The Habby Place. All rights reserved.</p>
            </div>
        </div>
    </footer>
    );
}

export default Footer;

import React, { useEffect,useState, useRef } from 'react';
import { useTransition } from '../../../contexts/TransitionContext';
import './HabbyTitle.css';
import { motion, useAnimation } from 'framer-motion';

function HabbyTitle() {
  const { skyBackground,animationTrigger } = useTransition();
  const controls = useAnimation();
  const floatRef = useRef(null);
  const backgroundRef = useRef(skyBackground); // Add this line
  const [background, setBackground] = useState(skyBackground);


  // Add a color interpolation function
  const lerpColor = (color1, color2, factor) => {
    // Simple linear interpolation between two colors
    const result = color1.slice(0);
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return `rgb(${result[0]}, ${result[1]}, ${result[2]})`;
  };
  useEffect(() => {
    if (!skyBackground || !backgroundRef.current) {
      // Guard clause if skyBackground or backgroundRef.current is not in the expected format
      console.error("Background not initialized or skyBackground is null.");
      return;
    }
  
    let start;
    const duration = 1000; // Duration of the transition in milliseconds
  
    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = Math.min((timestamp - start) / duration, 1);
  
      // Make sure both backgroundRef.current and skyBackground contain the expected color format
      const initialColorsMatch = backgroundRef.current.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/g);
      const targetColorsMatch = skyBackground.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/g);
      if (!initialColorsMatch || !targetColorsMatch) {
        console.error("Backgrounds do not match the expected format.");
        return;
      }
  
      const initialColors = initialColorsMatch.map(c => c.match(/\d+/g).map(Number));
      const targetColors = targetColorsMatch.map(c => c.match(/\d+/g).map(Number));
  
      const newBackground = initialColors.map((color, index) => {
        const tc = targetColors[index];
        return lerpColor(color, tc, progress);
      }).join(', ');
  
      setBackground(`linear-gradient(to bottom, ${newBackground})`);
  
      if (progress < 1) {
        requestAnimationFrame(step);
      } else {
        backgroundRef.current = skyBackground; // Update ref to the new background
      }
    };
  
    requestAnimationFrame(step);
  }, [skyBackground]);
  
  useEffect(() => {
    const elements = Array.from(floatRef.current.children);
    let animationFrameId;

    const updateAnimations = () => {
      const currentTime = Date.now();
      elements.forEach((element, index) => {
        const phase = (2 * Math.PI * index) / elements.length;
        const translateY = Math.sin(currentTime / 1000 + phase) * 10;
        const currentAngle = element.dataset.angle ? parseFloat(element.dataset.angle) : 0;
        const isHovering = element.dataset.isHovering === 'true';
        let velocity = element.dataset.velocity ? parseFloat(element.dataset.velocity) : 0;

        if (isHovering) {
          velocity += 0.5;
        } else {
          velocity *= 0.95;
        }

        const newAngle = currentAngle + velocity;
        element.style.transform = `translateY(${translateY}px) rotate(${newAngle}deg)`;
        element.dataset.angle = newAngle;
        element.dataset.velocity = velocity;
      });

      animationFrameId = requestAnimationFrame(updateAnimations);
    };

    elements.forEach((element) => {
      element.dataset.isHovering = 'false';
      element.dataset.velocity = '0';
      element.dataset.angle = '0';

      element.addEventListener('mouseenter', () => {
        element.dataset.isHovering = 'true';
      });

      element.addEventListener('mouseleave', () => {
        element.dataset.isHovering = 'false';
      });
    });

    updateAnimations();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [controls, skyBackground]); // Adjusted to include controls as dependency


  useEffect(() => {
    // Trigger animations based on the animationTrigger state
    if (animationTrigger) {
      controls.start("exit");
    } else {
      controls.start("enter");
    }
  }, [animationTrigger, controls]);


  // Animation variants for the container
  const containerVariants = {
    enter: {
      y: 0, 
      opacity: 1,
      transition: { duration: 0.5 }
    },
    exit: {
      y: "-100vh",
      opacity: 0,
      transition: { duration: 0.5, delay: 0.2 }
    }
  };


  // Ensure the correct structure by placing the return statement properly
  return (
    <div className="sky-background" style={{marginTop:'55px', background: background }}>
      <motion.div
        className="floating-header" 
        ref={floatRef}
        variants={containerVariants}
        initial="enter"
        animate={controls}
      >
        <img src="/header/h.png" alt="H" />
        <img src="/header/a.png" alt="A" />
        <img src="/header/b1.png" alt="B" />
        <img src="/header/b2.png" alt="B" />
        <img src="/header/y.png" alt="Y" />
      </motion.div>

      <div className="cloud-container">
        <div className="clouds">
          <img src="/header/clouds2.png" alt="clouds" className="cloud-image" />
          <img src="/header/clouds2.png" alt="clouds" className="cloud-image" />
        </div>
        <div className="clouds slow-clouds">
          <img src="/header/clouds3.png" alt="clouds" className="cloud-image" />
          <img src="/header/clouds3.png" alt="clouds" className="cloud-image" />
        </div>
      </div>
    </div>
  );
}

export default HabbyTitle;

import React, { createContext, useContext, useState } from 'react';

const TransitionContext = createContext();

export const useTransition = () => useContext(TransitionContext);

export const TransitionProvider = ({ children }) => {
  const [skyBackground, setSkyBackground] = useState('linear-gradient(to bottom, rgb(100, 178, 223) 0%, rgb(90, 163, 208) 50%, rgb(80, 148, 193) 100%)');
  const [animationTrigger, setAnimationTrigger] = useState(false); // For controlling animations

  const goToHome = () => {
    setSkyBackground('linear-gradient(to bottom, rgb(100, 178, 223) 0%, rgb(90, 163, 208) 50%, rgb(80, 148, 193) 100%)');
    setAnimationTrigger(false);
  };

  const goToPosters = () => {
    setSkyBackground('linear-gradient(to bottom, rgb(255, 107, 107) 0%, rgb(255, 142, 83) 50%, rgb(255, 174, 0) 100%)');
    setAnimationTrigger(true);
  };

  const goToArt = () => {
    setSkyBackground('linear-gradient(to top, rgb(130, 181, 216) 0%, rgb(175, 228, 255) 50%, rgb(223, 247, 255) 100%)');
    setAnimationTrigger(true); // Trigger specific animation for the art page
  };

  const goToParedolia = () => {
    setSkyBackground('linear-gradient(to top, rgb(130, 181, 216) 0%, rgb(175, 228, 255) 50%, rgb(223, 247, 255) 100%)');
    setAnimationTrigger(true); // Trigger specific animation for the art page
  };

  const toggleSunset = () =>{

       //if night colors
  if(skyBackground === 'linear-gradient(to bottom, rgb(50, 5, 20) 0%, rgb(50, 20, 83) 50%, rgb(70, 60, 100) 100%)'){
    setSkyBackground('linear-gradient(to bottom, rgb(100, 178, 223) 0%, rgb(90, 163, 208) 50%, rgb(80, 148, 193) 100%)')
  }
    //if sunset colors
  if(skyBackground === 'linear-gradient(to bottom, rgb(255, 107, 107) 0%, rgb(255, 142, 83) 50%, rgb(255, 174, 0) 100%)'){
    setSkyBackground('linear-gradient(to bottom, rgb(50, 5, 20) 0%, rgb(50, 20, 83) 50%, rgb(70, 60, 100) 100%)')
  }
  //if day Colors
  else if(skyBackground ==='linear-gradient(to bottom, rgb(100, 178, 223) 0%, rgb(90, 163, 208) 50%, rgb(80, 148, 193) 100%)'){
  setSkyBackground('linear-gradient(to bottom, rgb(255, 107, 107) 0%, rgb(255, 142, 83) 50%, rgb(255, 174, 0) 100%)');
  }
}

  const triggerTransition = (toPage) => {
    switch(toPage) {
      case 'home':
        goToHome();
        break;
      case 'posters':
        goToPosters();
        break;
      case 'art':
        goToArt();
        break;
      case 'paredolia':
        goToParedolia();
        break;
      default:
        setSkyBackground('linear-gradient(to bottom, rgb(100, 178, 223) 0%, rgb(90, 163, 208) 50%, rgb(80, 148, 193) 100%)');
        setAnimationTrigger(false);
    }
  };

  return (
    <TransitionContext.Provider value={{ skyBackground,goToParedolia, goToHome, goToPosters, goToArt, triggerTransition, animationTrigger, toggleSunset }}>
      {children}
    </TransitionContext.Provider>
  );
};

// P5Context.js
import React, { createContext, useContext, useState } from 'react';

const P5Context = createContext();

export const useP5 = () => useContext(P5Context);

export const P5Provider = ({ children }) => {
    const [currentProject, setCurrentProject] = useState('DaysLivedVisualiser');

    // You can add more shared state and functions here
    const selectProject = project => {
        setCurrentProject(project);
    };

    return (
        <P5Context.Provider value={{ currentProject, selectProject }}>
            {children}
        </P5Context.Provider>
    );
};

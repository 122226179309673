import React, { useEffect, useState } from 'react';

import PosterHeader from '../../components/header/PosterHeaderBar/PosterHeader';


import Footer from '../../components/footer/footer';

import './PondPage.css';
import FishTank from '../../components/p5/FishTank';

function PondPage() {


return (
  <div className="relative w-full min-h-screen">

    <PosterHeader />
    <div className="relative z-10">
      
<FishTank/>

    </div>
    <Footer />
  </div>
);

}

export default PondPage;


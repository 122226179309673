import React, { useEffect, useRef } from 'react';
import p5 from 'p5';
import { Howl } from 'howler';

const Piano = () => {
    const sketchRef = useRef(null);

    useEffect(() => {
        let myP5;
        const sounds = {};
        const whiteKeys = [];
        const blackKeys = [];

        // Define the notes and load the corresponding sound files
        const notes = [
            'C3', 'D3', 'E3', 'F3', 'G3', 'A3', 'B3', 'Db3', 'Eb3', 'Gb3', 'Ab3', 'Bb3',
            'C4', 'D4', 'E4', 'F4', 'G4', 'A4', 'B4', 'Db4', 'Eb4', 'Gb4', 'Ab4', 'Bb4',
            'C5', 'D5', 'E5', 'F5', 'G5', 'A5', 'B5', 'Db5', 'Eb5', 'Gb5', 'Ab5', 'Bb5'
        ];
        notes.forEach(note => {
            sounds[note] = new Howl({
                src: [`./notes/Piano.mf.${note}.mp3`],
                onload: () => console.log(`${note} loaded`),
                onloaderror: (id, err) => console.error(`${note} failed to load`, err),
                onplayerror: (id, err) => console.error(`Error playing ${note}`, err)
            });
        });

        const sketch = (p) => {
            class Key {
                constructor(x, y, w, h, note, color) {
                    this.x = x;
                    this.y = y;
                    this.w = w;
                    this.h = h;
                    this.note = note;
                    this.color = color;
                    this.isPressed = false;
                }

                draw() {
                    p.fill(this.isPressed ? 'gray' : this.color);
                    p.rect(this.x, this.y, this.w, this.h);
                }

                playSound() {
                    if (sounds[this.note]) {
                        console.log(`Playing sound for note: ${this.note}`);
                        sounds[this.note].play();
                    } else {
                        console.log(`Sound file for note ${this.note} not found.`);
                    }
                }

                contains(px, py) {
                    return px > this.x && px < this.x + this.w && py > this.y && py < this.y + this.h;
                }
            }

            function semitoneOffset(note) {
                const offsets = {
                    'C': 0, 'Db': 1, 'D': 2, 'Eb': 3, 'E': 4, 'F': 5, 
                    'Gb': 6, 'G': 7, 'Ab': 8, 'A': 9, 'Bb': 10, 'B': 11
                };
                return offsets[note.substring(0, note.length - 1)] || 0;
            }

            p.setup = () => {
                const keyWidth = 90;
                const keyHeight = 400;  // Height for keys
                const totalKeys = 21;   // Total number of white keys visible
                const canvasWidth = Math.max(p.windowWidth, totalKeys * keyWidth); // Ensure the canvas is at least as wide as the screen
                p.createCanvas(canvasWidth, keyHeight);
                const startX = (canvasWidth - totalKeys * keyWidth) / 2; // Centering the piano

                // Create white keys starting from C4
                for (let i = 0; i < totalKeys; i++) {
                    const octave = Math.floor(i / 7) + 4; // Adds 4 to start at C4
                    const note = ['C', 'D', 'E', 'F', 'G', 'A', 'B'][i % 7] + octave;
                    whiteKeys.push(new Key(startX + i * keyWidth, 0, keyWidth, keyHeight, note, '#f9f6ec'));
                }

                // Create black keys correctly positioned and labeled
                for (let i = 0; i < totalKeys - 1; i++) {
                    if (['E', 'B'].includes(whiteKeys[i].note[0])) continue; // Skip black keys after E and B
                    let note;
                    if (whiteKeys[i].note[0] === 'B') {
                        const nextOctave = parseInt(whiteKeys[i].note.substring(1)) + 1;
                        note = 'Db' + nextOctave;
                    } else if (whiteKeys[i].note[0] === 'E') {
                        const currentOctave = parseInt(whiteKeys[i].note.substring(1));
                        note = 'Gb' + currentOctave;
                    } else {
                        const currentOctave = parseInt(whiteKeys[i].note.substring(1));
                        const nextNote = getNextNote(whiteKeys[i].note[0]);
                        note = nextNote + 'b' + currentOctave;
                    }
                    blackKeys.push(new Key(startX + i * keyWidth + 0.67 * keyWidth, 0, 0.66 * keyWidth, 0.67 * keyHeight, note, '#212121'));
                }
            };

            function getNextNote(currentNote) {
                const notes = ['C', 'D', 'E', 'F', 'G', 'A', 'B'];
                const index = notes.indexOf(currentNote);
                return notes[(index + 1) % 7];
            }

            p.draw = () => {
                p.background("#212121"); // Dark background color
                whiteKeys.forEach(key => key.draw());
                blackKeys.forEach(key => key.draw());
            };

            p.mousePressed = () => {
                let anyKeyPressed = false;
                for (let key of blackKeys) {
                    if (key.contains(p.mouseX, p.mouseY)) {
                        key.isPressed = true;
                        key.playSound();
                        anyKeyPressed = true;
                        break;
                    }
                }
                if (!anyKeyPressed) {
                    for (let key of whiteKeys) {
                        if (key.contains(p.mouseX, p.mouseY)) {
                            key.isPressed = true;
                            key.playSound();
                            break;
                        }
                    }
                }
            };

            p.mouseReleased = () => {
                [...whiteKeys, ...blackKeys].forEach(key => key.isPressed = false);
            };
        };

        myP5 = new p5(sketch, sketchRef.current);

        return () => {
            if (myP5) {
                myP5.remove();
            }
        };
    }, []);

    return <div ref={sketchRef} className="piano-container" style={{ width: '100%', overflowX: 'auto' }} />;
};

export default Piano;

import React, { useState } from 'react';
import P5Header from '../../components/header/P5HeaderBar/P5Header';
import PareidoliaGenerator from '../../components/p5/PareidoliaGenerator';

const App = () => {
  const [isPaused, setIsPaused] = useState(false);

  const togglePause = () => {
    setIsPaused(!isPaused);
    console.log(isPaused, "isPaused")
  };

  // Custom font style using a font face defined within the component
  const customFontStyle = {
    fontFamily: "coolvetica",
    src: `url('/coolvetica.ttf') format('truetype')`  // Adjust the path if your font is in a subdirectory
  };

  return (
    <div style={{ height: '100vh', position: 'relative', backgroundColor: 'black', color: 'white' }}>
      {/* Header positioned absolutely to overlay on the content */}
      <P5Header style={{ position: 'absolute', top: 0, width: '100%', zIndex: 10 }}/>
      <h1 style={{
          position: 'absolute',
          fontSize: 50,
          lineHeight: '60px', // Adjust line height for larger screens
          opacity: 0.4,
          top: '12%',
          width: '100%',
          textAlign: 'center',
          zIndex: 5,
          ...customFontStyle  // Apply the custom font style here
        }}>
        Pareidolia Generator
      </h1>
      <p style={{ position: 'relative', fontSize: 15, opacity: 0.4, top: '10%', width: '100%', textAlign: 'center', zIndex: 5 }}>
        (click screen to pause)
      </p>
      <PareidoliaGenerator isPaused={isPaused} togglePause={togglePause} />

      {/* Adding mobile-specific styles */}
      <style>
        {`@media (max-width: 768px) {
          h1 {
            fontSize: 30px; // Smaller font size for mobile
            lineHeight: '35px'; // Smaller line height for mobile
          }
        }`}
      </style>
    </div>
  );
};

export default App;

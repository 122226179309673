import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { TransitionProvider } from './contexts/TransitionContext';
import { P5Provider } from './contexts/P5Context';
import NavBar from './components/header/TopNavBar/NavBar';
import HabbyTitle from './components/header/HabbyTitle/HabbyTitle';
import HomePage from './pages/HomePage/HomePage';
import PosterPage from './pages/PosterPage';
import P5Page from './pages/p5Page/P5Page';
import PareidoliaPage from './pages/PareidoliaPage/PareidoliaPage';
import client from './components/shopify/ApolloClient'; // Ensure this path is correct
import './App.css';
import PareidoliaGenerator from './components/p5/PareidoliaGenerator';
import PondPage from './pages/PondPage/PondPage';

const AppWrapper = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <TransitionProvider>
        <P5Provider>
          <App />
          </P5Provider>
        </TransitionProvider>
      </Router>
    </ApolloProvider>
  );
};
const App = () => {
  // No need to extract 'transition' if it's not used directly here
  return (
    <div className="App">
      
   
      <Routes>
        <Route path="/" element={    <div><HabbyTitle />  <HomePage /></div>} />
        <Route path="/posters" element={<PosterPage />} />
    
            
        <Route path="/paredolia" element={<PareidoliaPage />} />
        <Route path="/art" element={<P5Page />} />
        <Route path="/pond" element={<PondPage />} />
       
        {/* Define other routes as needed */}
      </Routes>
    </div>
  );
};

export default AppWrapper;
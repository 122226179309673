import React, { useEffect, useRef, useState } from 'react';
import p5 from 'p5';

const FishTank = () => {
    let obstacleImage = '../lilypad.png'; // Replace with your actual image path

    const sketchRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        function updateDimensions() {
            if (sketchRef.current) {
                setDimensions({
                    width: sketchRef.current.offsetWidth,
                    height: sketchRef.current.offsetHeight
                });
            }
        }

        // Call when component mounts
        updateDimensions();

        // Add event listener to resize on window resize
        window.addEventListener('resize', updateDimensions);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect(() => {
        const myP5 = new p5((p) => {
            let flock;
            let predator;
            let obstacles = [];
            let foods = [];

            p.setup = () => {
                p.createCanvas(p.windowWidth, p.windowHeight).parent(sketchRef.current);
                p.background(20, 59, 133); // Dark blue background

                flock = new Flock(p);
                for (let i = 0; i < 55; i++) {
                    let boid = new Boid(p, p.random(p.width), p.random(p.height));
                    flock.addBoid(boid);
                }
                
                // Initialize predator
                predator = new Predator(p, p.random(p.width), p.random(p.height));

                // Initialize obstacles
                const sizes = [30, 50, 65];
                const rotations = [0, 45, 90];
                for (let i = 0; i < 3; i++) {
                    const x = p.random(p.width);
                    const y = p.random(p.height);
                    obstacles.push(new Obstacle(p, x, y, obstacleImage, sizes[i], rotations[i]));
                }
            };

            p.draw = () => {
                p.background(56, 186, 151); // Refresh background
              
                foods.forEach(food => {
                    food.display();
                });
                flock.run(obstacles, foods, predator);
                predator.run(flock.boids);

                obstacles.forEach(obstacle => {
                    obstacle.display();
                });
            };

            p.mousePressed = () => {
                let clickedOnObstacle = obstacles.some(obstacle => {
                    return p.dist(p.mouseX, p.mouseY, obstacle.position.x, obstacle.position.y) < obstacle.radius;
                });

                if (!clickedOnObstacle) {
                    foods.push(new Food(p, p.mouseX, p.mouseY));
                }
            };

            p.mouseDragged = () => {
                obstacles.forEach(obstacle => {
                    if (p.dist(p.mouseX, p.mouseY, obstacle.position.x, obstacle.position.y) < obstacle.radius) {
                        obstacle.position.set(p.mouseX, p.mouseY);
                    }
                });
            };

            class Predator {
                constructor(p, x, y) {
                    this.p = p;
                    this.acceleration = p.createVector(0, 0);
                    this.velocity = p.createVector(p.random(-1, 1), p.random(-1, 1));
                    this.position = p.createVector(x, y);
                    this.r = 12.0; // Slightly bigger radius for the predator
                    this.maxspeed = 5; // Faster than boids
                    this.maxforce = 0.1;
                }

                run(boids) {
                    this.flock(boids);
                    this.update();
                    this.borders();
                    this.render();
                }

                flock(boids) {
                    let closest = null;
                    let closestD = Infinity;
                    for (let i = 0; i < boids.length; i++) {
                        let d = this.p.dist(this.position.x, this.position.y, boids[i].position.x, boids[i].position.y);
                        if (d < closestD) {
                            closestD = d;
                            closest = boids[i];
                        }
                    }

                    if (closest && closestD < 200) {
                        let pursuit = this.seek(closest.position);
                        pursuit.mult(1.5); // Stronger pursuit force
                        this.applyForce(pursuit);
                        if (closestD < 15) {
                            boids.splice(boids.indexOf(closest), 1); // Eat the boid
                        }
                    }
                }

                applyForce(force) {
                    this.acceleration.add(force);
                }

                update() {
                    this.velocity.add(this.acceleration);
                    this.velocity.limit(this.maxspeed);
                    this.position.add(this.velocity);
                    this.acceleration.mult(0);
                }

                render() {
                    let theta = this.velocity.heading() + this.p.radians(90);
                    this.p.fill(255, 0, 0);
                    this.p.stroke(200, 0, 0);
                    this.p.push();
                    this.p.translate(this.position.x, this.position.y);
                    this.p.rotate(theta);
                    this.p.beginShape();
                    this.p.vertex(0, -this.r * 2);
                    this.p.vertex(-this.r, this.r * 2);
                    this.p.vertex(this.r, this.r * 2);
                    this.p.endShape(this.p.CLOSE);
                    this.p.pop();
                }

                borders() {
                    if (this.position.x < -this.r) this.position.x = this.p.width + this.r;
                    if (this.position.y < -this.r) this.position.y = this.p.height + this.r;
                    if (this.position.x > this.p.width + this.r) this.position.x = -this.r;
                    if (this.position.y > this.p.height + this.r) this.position.y = -this.r;
                }

                seek(target) {
                    let desired = this.p.createVector(target.x - this.position.x, target.y - this.position.y);
                    desired.normalize();
                    desired.mult(this.maxspeed);
                    let steer = this.p.createVector(desired.x - this.velocity.x, desired.y - this.velocity.y);
                    steer.limit(this.maxforce);
                    return steer;
                }
            }

            class Flock {
                constructor(p) {
                    this.p = p;
                    this.boids = [];
                }

                addBoid(boid) {
                    this.boids.push(boid);
                }

                run(obstacles, foods, predator) {
                    this.boids.forEach(boid => {
                        boid.run(this.boids,predator, obstacles, foods);
                    });
                }
            }

            // Boid and other classes (Food, Obstacle) remain unchanged
            class Boid {
                constructor(p, x, y) {
                    this.p = p;
                    this.acceleration = p.createVector(0, 0);
                    this.velocity = p.createVector(p.random(-1, 1), p.random(-1, 1));
                    this.position = p.createVector(x, y);
                    this.r = 3.0;
                    this.maxspeed = 4.5;
                    this.maxforce = 0.01;
                }


                eat(foods) {
                    foods.forEach((food, index) => {
                        let d = this.p.dist(this.position.x, this.position.y, food.position.x, food.position.y);
                        if (d < 150) { // Boids go to food if within 50 pixels
                            let eatForce = this.seek(food.position);
                            eatForce.mult(3); // Stronger force towards food
                            this.applyForce(eatForce);
                        }
                        if (d < 5) {
                            foods.splice(index, 1); // Remove food when boid reaches it
                        }
                    });
                }
            
                run(boids, predator, obstacles, foods) {
                    this.flock(boids);
                    this.evade(predator);
                    this.update();
                    this.borders();
                    this.render();
                    this.avoid(obstacles);
                    this.eat(foods);
                }
            
                // Evade method to avoid the predator
                evade(predator) {
                    let threatDistance = 75; // distance within which boids should start evading the predator
                    let avoidForce = p.createVector(0, 0);
                    let d = this.p.dist(this.position.x, this.position.y, predator.position.x, predator.position.y);
                    
                    if (d < threatDistance) {
                        avoidForce = p5.Vector.sub(this.position, predator.position);
                        avoidForce.setMag(this.maxspeed);
                        avoidForce.mult(1.5); // Stronger evasion force
                        this.applyForce(avoidForce);
                    }
                }
            
                applyForce(force) {
                    this.acceleration.add(force);
                }
            
                update() {
                    this.velocity.add(this.acceleration);
                    this.velocity.limit(this.maxspeed);
                    this.position.add(this.velocity);
                    this.acceleration.mult(0);
                }
            
                render() {
                    let theta = this.velocity.heading() + this.p.radians(90);
                    this.p.fill(255, 165, 10);
                    this.p.stroke(155, 110, 5);
                    this.p.push();
                    this.p.translate(this.position.x, this.position.y);
                    this.p.rotate(theta);
                    this.p.beginShape();
                    this.p.vertex(0, -this.r * 2);
                    this.p.vertex(-this.r, this.r * 2);
                    this.p.vertex(this.r, this.r * 2);
                    this.p.endShape(this.p.CLOSE);
                    this.p.pop();
                }
            
                borders() {
                    if (this.position.x < -this.r) this.position.x = this.p.width + this.r;
                    if (this.position.y < -this.r) this.position.y = this.p.height + this.r;
                    if (this.position.x > this.p.width + this.r) this.position.x = -this.r;
                    if (this.position.y > this.p.height + this.r) this.position.y = -this.r;
                }
            
                flock(boids) {
                    let sep = this.separate(boids); // Separation
                    let ali = this.align(boids); // Alignment
                    let coh = this.cohesion(boids); // Cohesion
                    sep.mult(1.5);
                    ali.mult(1.0);
                    coh.mult(1.0);
                    this.applyForce(sep);
                    this.applyForce(ali);
                    this.applyForce(coh);
                }
            

                // Methods for separate, align, cohesion and avoid are similar to those provided earlier

    // Separation
    // Method checks for nearby boids and steers away
    separate(boids) {
        let desiredseparation = 25.0;
        let steer = p.createVector(0, 0);
        let count = 0;
        // For every boid in the system, check if it's too close
        for (let i = 0; i < boids.length; i++) {
            let d = p5.Vector.dist(this.position, boids[i].position);
            // If the distance is greater than 0 and less than an arbitrary amount (0 when you are yourself)
            if ((d > 0) && (d < desiredseparation)) {
                // Calculate vector pointing away from neighbor
                let diff = p5.Vector.sub(this.position, boids[i].position);
                diff.normalize();
                diff.div(d); // Weight by distance
                steer.add(diff);
                count++; // Keep track of how many
            }
        }
        // Average -- divide by how many
        if (count > 0) {
            steer.div(count);
        }

        // As long as the vector is greater than 0
        if (steer.mag() > 0) {
            // Implement Reynolds: Steering = Desired - Velocity
            steer.normalize();
            steer.mult(this.maxspeed);
            steer.sub(this.velocity);
            steer.limit(this.maxforce);
        }
        return steer;
    }


     // A method that calculates and applies a steering force towards a target
     seek(target) {
        let desired = p5.Vector.sub(target, this.position); // A vector pointing from the position to the target
        desired.normalize();
        desired.mult(this.maxspeed);
        let steer = p5.Vector.sub(desired, this.velocity);
        steer.limit(this.maxforce); // Limit to maximum steering force
        return steer;
    }

    // Alignment
    // For every nearby boid in the system, calculate the average velocity
    align(boids) {
        let neighbordist = 50;
        let sum = p.createVector(0, 0);
        let count = 0;
        for (let i = 0; i < boids.length; i++) {
            let d = p5.Vector.dist(this.position, boids[i].position);
            if ((d > 0) && (d < neighbordist)) {
                sum.add(boids[i].velocity);
                count++;
            }
        }
        if (count > 0) {
            sum.div(count);
            sum.normalize();
            sum.mult(this.maxspeed);
            let steer = p5.Vector.sub(sum, this.velocity);
            steer.limit(this.maxforce);
            return steer;
        } else {
            return p.createVector(0, 0);
        }
    }

    // Cohesion
    // For the average position (i.e., center) of all nearby boids, calculate steering vector towards that position
    cohesion(boids) {
        let neighbordist = 50;
        let sum = p.createVector(0, 0); // Start with empty vector to accumulate all positions
        let count = 0;
        for (let i = 0; i < boids.length; i++) {
            let d = p5.Vector.dist(this.position, boids[i].position);
            if ((d > 0) && (d < neighbordist)) {
                sum.add(boids[i].position); // Add location
                count++;
            }
        }
        if (count > 0) {
            sum.div(count);
            return this.seek(sum); // Steer towards the position
        } else {
            return p.createVector(0, 0);
        }
    }

    // A method to avoid obstacles
    avoid(obstacles) {
        obstacles.forEach(obstacle => {
            let d = p5.Vector.dist(this.position, obstacle.position);
            if (d < obstacle.radius + this.r * 2) { // Check for collision
                let avoidForce = p5.Vector.sub(this.position, obstacle.position);
                avoidForce.setMag(this.maxspeed);
                avoidForce.mult(1.5); // Stronger avoidance force
                this.applyForce(avoidForce);
            }
        });
    }

            }

            class Food {
                constructor(p, x, y) {
                    this.p = p;
                    this.position = p.createVector(x, y);
                    this.color = p.color(255, 204, 0); // Color for food
                }

                display() {
                    this.p.fill(this.color);
                    this.p.ellipse(this.position.x, this.position.y, 8, 8); // Display food as small circles
                }
            }


            class Obstacle {
                constructor(p, x, y, imgPath, radius, angle = 0) {
                    this.p = p;
                    this.position = p.createVector(x, y);
                    this.radius = radius;
                    this.angle = angle;  // Rotation angle in degrees
                    this.img = p.loadImage(imgPath, img => {
                        // Resize the image to fit the specified radius
                        img.resize(this.radius * 2, this.radius * 2);
                    });
                }
            
                display() {
                    // Adjust image mode to center
                    this.p.imageMode(this.p.CENTER);
                    // Apply rotation
                    this.p.push();
                    this.p.translate(this.position.x, this.position.y);
                    this.p.rotate(this.p.radians(this.angle));
                    this.p.image(this.img, 0, 0);
                    this.p.pop();
                }
            }

        });

        return () => {
            myP5.remove(); // Clean up the sketch on component unmount
        };
    }, [dimensions]); // Re-run this effect when dimensions change

    return (
        <div ref={sketchRef} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '25px', width: '100%', height: '100%' }}></div>
    );
};

export default FishTank;
